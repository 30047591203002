import { type PrivyClientConfig, PrivyProvider } from "@privy-io/react-auth";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  data,
} from "@vercel/remix";
import mixpanel from "mixpanel-browser";
import { Suspense, lazy, useEffect } from "react";
import toast from "react-hot-toast/headless";
import { getToast } from "remix-toast";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { getClientLocales } from "remix-utils/locales/server";

import * as gtag from "~/utils/gtag.client";
import FriendsList from "~/components/FriendsList";
import Toaster from "~/components/Toaster";
import LoginModal from "~/components/modals/LoginModal";
import { href as svgIcons } from "~/components/ui/icons/icon";
import { FriendsSidebarProvider } from "~/providers/FriendsContext";
import { GameProvider } from "~/providers/GameContext";
import { GraphQLProvider } from "~/providers/GraphQLProvider";
import { NotificationsProvider } from "~/providers/NotificationsContext";
import "~/styles/tailwind.css";
import { type SerializeFrom } from "~/utils";
import { getUserSession } from "~/utils/auth.server";
import { honeypot } from "~/utils/honeypot.server";
import { hytopiaClient } from "~/utils/hytopiaClient";

export const links: LinksFunction = () => [
  { rel: "preload", href: svgIcons, as: "image" },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-touch-icon.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png",
  },
  {
    rel: "manifest",
    href: "/site.webmanifest",
  },
];

export const meta: MetaFunction = () => {
  const title = "HYTOPIA";
  const description =
    "Explore HYTOPIA, a delightful, free-to-play gaming world where you can explore, create, and share endless fun and excitement, inspired by the best of Minecraft. Dive into a universe of joy and imagination today!";

  return [
    { title },
    {
      name: "description",
      content: description,
    },
    {
      name: "og:image",
      content: "https://hytopia.com/img/og.jpg",
    },
    {
      name: "og:title",
      content: title,
    },
    {
      name: "og:description",
      content: description,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "apple-mobile-web-app-status-bar-style",
      content: "black-translucent",
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const honeyProps = honeypot.getInputProps();
  const { toast, headers } = await getToast(request);
  const currentUser = await getUserSession(request);
  const preferredLocale = getClientLocales(request)
    ? getClientLocales(request)?.[0]
    : "en-US";

  const topiaBalance = "0";

  const usdcBalance = currentUser
    ? hytopiaClient.bank.users
        .getLoggedInUserBalance(currentUser.accessToken)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error.message);
          }

          const hyBux = (Number(res.balance) - Number(res.lockedBalance)) * 100;

          return new Intl.NumberFormat(preferredLocale).format(hyBux);
        })
        .catch(() => "0")
    : "0";

  const friends = currentUser
    ? hytopiaClient.social.friends
        .getFriendships(currentUser.accessToken)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error.message);
          }

          return res;
        })
        .catch(() => null)
    : undefined;

  const friendRequests = currentUser
    ? hytopiaClient.social.friendRequests
        .getFriendRequests(currentUser.accessToken)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error.message);
          }

          return res;
        })
        .catch(() => null)
    : undefined;

  return data(
    {
      toast,
      friends,
      usdcBalance,
      topiaBalance,
      friendRequests,
      preferredLocale,
      currentUser: currentUser
        ? {
            id: currentUser.id,
            username: currentUser.username,
            accessToken: currentUser.accessToken,
            profilePictureURL: currentUser.profilePictureURL,
            wallet: {
              address: currentUser.walletAddress,
            },
          }
        : undefined,
      hotjarId: process.env.HOTJAR_ID,
      privyAppId: process.env.PRIVY_APP_ID,
      googleAdsId: process.env.GOOGLE_ADS_ID,
      gaTrackingId: process.env.GA_TRACKING_ID,
      alchemyApiKey: process.env.ALCHEMY_API_KEY,
      honeypotSecret: process.env.HONEYPOT_SECRET,
      mixpanelProjectId: process.env.MIXPANEL_PROJECT_ID,
      hCaptchaSiteKey: process.env.HCAPTCHA_SITE_KEY,
      stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
      hytopiaClientBaseUrl: process.env.HYTOPIA_CLIENT_BASE_URL,
      hytopiaGraphqlUrl: process.env.HYTOPIA_GRAPHQL_URL,
      honeyProps: {
        nameFieldName: honeyProps.nameFieldName,
        validFromFieldName: honeyProps.validFromFieldName,
        encryptedValidFrom: honeyProps.encryptedValidFrom,
      },
    },
    { headers },
  );
}

export function Document({
  children,
  loaderData,
}: {
  children: React.ReactNode;
  loaderData?: SerializeFrom<typeof loader>;
}) {
  const matches = useMatches();
  const hasPlaybar = matches.some(
    (match) =>
      match.data &&
      typeof match.data === "object" &&
      "hasPlaybar" in match.data,
  );
  const isWagmiRoute = matches.some((match) => {
    if (typeof match.id === "string" && match.id.includes("_wagmi")) {
      return true;
    }
  });

  const privyConfig: PrivyClientConfig = {
    appearance: {
      theme: "dark",
      logo: "https://hytopia.com/img/logo.svg",
    },
    loginMethodsAndOrder: {
      primary: ["google", "twitter", "discord"],
      overflow: ["email"],
    },
    embeddedWallets: {
      createOnLogin: "off",
    },
  };

  return (
    <html
      lang="en"
      className="h-full overflow-x-hidden overflow-y-scroll bg-[#0D0D0D] text-[11px] text-white [color-scheme:dark] 3xl:text-xs"
    >
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
        />
        <meta name="theme-color" content="#0f0f0f" />
        <Meta />
        <Links />
        {process.env.NODE_ENV === "development" ||
        !loaderData?.gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${loaderData.gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${loaderData.gaTrackingId}', {
                    page_path: window.location.pathname,
                  });

                  ${
                    loaderData.googleAdsId
                      ? `gtag('config', '${loaderData.googleAdsId}');`
                      : ""
                  }
                `,
              }}
            />
          </>
        )}
        {process.env.NODE_ENV === "development" ||
        !loaderData?.hotjarId ? null : (
          <script
            id="hotjar-init"
            dangerouslySetInnerHTML={{
              __html: `
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${loaderData.hotjarId},hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
            }}
          />
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "HYTOPIA",
          "logo": "https://hytopia.com/img/logo.svg",
          "url": "https://hytopia.com",
          "sameAs": [
            "https://twitter.com/HYTOPIAgg",
            "https://discord.gg/hychain"
          ]
        }
        `,
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@context": "http://schema.org",
                "@type": "VideoGame",
                "name": "HYTOPIA",
                "description": "HYTOPIA is a friendly, vibrant place where you can play, create, and explore without limits. Inspired by the best parts of Minecraft, we've made everything easy and exciting - it's all about having fun with friends, sharing awesome experiences, and letting your imagination run wild! And the best part? It's completely free to join the adventure! So, what are you waiting for? Discover the endless joys and surprises waiting for you in HYTOPIA",
                "genre": "Sandbox",
                "publisher": "HYTOPIA"
              }
            `,
          }}
        />
      </head>
      <body className="min-h-[100dvh]">
        {isWagmiRoute ? (
          children
        ) : (
          <GraphQLProvider graphqlUrl={loaderData?.hytopiaGraphqlUrl}>
            <PrivyProvider
              config={privyConfig}
              appId={String(loaderData?.privyAppId)}
            >
              <FriendsSidebarProvider>
                <NotificationsProvider>
                  <GameProvider>
                    {children}
                    <FriendsList />
                  </GameProvider>
                </NotificationsProvider>
              </FriendsSidebarProvider>
            </PrivyProvider>
          </GraphQLProvider>
        )}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify({
              STRIPE_PUBLISHABLE_KEY: loaderData?.stripePublishableKey,
              HYTOPIA_CLIENT_BASE_URL: loaderData?.hytopiaClientBaseUrl,
            })}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
        <Toaster hasPlaybar={hasPlaybar} />
      </body>
    </html>
  );
}

function App() {
  const location = useLocation();
  const loaderData = useLoaderData<typeof loader>();

  useEffect(() => {
    if (loaderData.gaTrackingId?.length) {
      gtag.pageview(location.pathname, loaderData.gaTrackingId);
    }
  }, [location, loaderData.gaTrackingId]);

  useEffect(() => {
    if (loaderData.mixpanelProjectId && "__loaded" in mixpanel) {
      mixpanel.track_pageview();
    }
  }, [location, loaderData.mixpanelProjectId]);

  useEffect(() => {
    if (loaderData.mixpanelProjectId) {
      mixpanel.init(loaderData.mixpanelProjectId, {
        ignore_dnt: true,
        persistence: "localStorage",
        debug: process.env.NODE_ENV === "development",
      });
    }
  }, [loaderData.mixpanelProjectId]);

  useEffect(() => {
    if (
      loaderData.toast &&
      (loaderData.toast.type === "error" || loaderData.toast.type === "success")
    ) {
      toast[loaderData.toast.type === "error" ? "error" : "success"](
        loaderData.toast.message,
      );
    }
  }, [loaderData.toast]);

  return (
    <Document loaderData={loaderData}>
      <HoneypotProvider {...loaderData.honeyProps}>
        <Outlet />
      </HoneypotProvider>
      <LoginModal />
    </Document>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  const loaderData = useRouteLoaderData<typeof loader>("root");
  const isNotFound =
    isRouteErrorResponse(error) && String(error.status).startsWith("4");
  const NotFound = lazy(() => import("~/components/errors/NotFound"));
  const ServerError = lazy(() => import("~/components/errors/ServerError"));

  captureRemixErrorBoundaryError(error);

  return (
    <Document loaderData={loaderData}>
      <Suspense>{isNotFound ? <NotFound /> : <ServerError />}</Suspense>
    </Document>
  );
}
